
import Vue from 'vue';

export default Vue.extend({
  metaInfo: {
    title: 'Inventory - '
  },
  name: 'Inventory',
  components: {},
  data: () => ({}),
  computed: {}
});
